@use '../abstracts/colors';
@use '../base/animations';
@use 'button';

.serviceCard {
	perspective: 150rem;
	position: relative;
	height: 42rem;
	width: 34rem;
	&Title {
		text-align: left;
		font-size: 2rem;
		border-bottom: 2px solid colors.$color-primary-light;
		display: inline;
		padding-bottom: 0.6rem;
		font-weight: 500;
		text-transform: uppercase;
		&Container {
			@apply flex items-center justify-center;
			letter-spacing: 0.2rem;
		}
	}
	&Side {
		transition: all 0.8s ease;
		font-size: 2rem;
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		backface-visibility: hidden;
		box-shadow: 0 1.5rem 4rem rgba(colors.$color-black, 0.15);
		@apply rounded;
		&Front {
			background-color: colors.$color-primary-light;
		}
		&Back {
			background-color: colors.$color-primary;
			transform: rotateY(180deg);
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 4rem 0 4rem 0;
		}
	}
	&Button {
		background-color: colors.$color-primary-light !important;
		&Container {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	&Details {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		align-items: center;
		justify-content: center;
		&Container {
			display: flex;
			/* padding: 4rem; */
			align-items: center;
			justify-content: center;
		}
	}
	&:hover &SideFront {
		// hover effect only on the card side
		transform: rotateY(-180deg);
	}
	&:hover &SideBack {
		// hover effect only on the card side
		transform: rotateY(0);
	}
	&ImageContainer {
		position: relative;
	}
}
