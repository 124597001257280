.hero {
	position: relative;
	overflow: hidden;
	&Img {
		&Box {
			height: 70vh;
			display: flex;
			position: relative;
			align-items: center;
			justify-content: center;
		}
	}
}
