@use '../abstracts/colors';
@use '../base/typography';
@use '../base/animations';
@use '../base/utilities';
@use '../components/ServiceCard.module.scss';

.services {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 8rem;
	&Container {
		margin: 8rem 0 8rem 0;
		display: flex;
		flex-direction: column;
		background-color: colors.$color-white;
		align-items: center;
		justify-content: center;
		gap: 8rem;
	}
}
// ABOVE 544px (For smaller tablets)
@media only screen and (min-width: 34em) {
}

// ABOVE 704px (For Tablets)
@media only screen and (min-width: 44em) {
}
// ABOVE 944px (For Landscape tablets)
@media only screen and (min-width: 59em) {
}

// ABOVE 1200px (For smaller desktops)
@media only screen and (min-width: 75em) {
}

// ABOVE 1344px (For desktops)
@media only screen and (min-width: 84em) {
	.services {
		flex-direction: column;
		&Container {
			display: flex;
			flex-direction: row;
			gap: 8rem;
			align-items: center;
			justify-content: center;
		}
	}
}

// ABOVE 1600px (For large desktops)
@media only screen and (min-width: 100em) {
	.services {
		&Container {
			gap: 16rem;
		}
	}
}

// ABOVE 1984px (For extra large desktops)
@media only screen and (min-width: 124em) {
	.services {
		&Container {
			gap: 16rem;
		}
	}
}
