@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes menuMoveInBottom {
	0% {
		opacity: 0;
		transform: translateY(2rem);
	}
	100% {
		opacity: 1;
		transform: translate(0);
	}
}

/* @keyframes moveInLeft {
	0% {
		opacity: 0;
		transform: translateX(-10rem);
	}
	80% {
		transform: translateX(1rem);
	}
	100% {
		opacity: 1;
		transform: translate(0);
	}
}

@keyframes growInLeft {
	0% {
		opacity: 0;
		width: 0%;
	}

	100% {
		opacity: 1;
		width: 7rem;
	}
}

@keyframes moveInRight {
	0% {
		opacity: 0;
		transform: translateX(10rem);
	}
	80% {
		transform: translateX(-1rem);
	}
	100% {
		opacity: 1;
		transform: translate(0);
	}
}

@keyframes moveInBottom {
	0% {
		opacity: 0;
		transform: translateY(20rem);
	}
	100% {
		opacity: 1;
		transform: translate(0);
	}
}

@keyframes scaleIn {
	from {
		opacity: 0;
		transform: scale(0.7);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes moveInBottomMobileMenu {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
 */