@use '../abstracts/colors';
@use '../base/typography';
@use '../base/animations';
@use '../base/utilities';

.articleCard {
	@apply flex flex-col justify-center cursor-pointer rounded;
	background-color: colors.$color-white;
	max-width: 44rem;
	min-width: 34rem;
	box-shadow: 0 1.5rem 4rem rgba(colors.$color-black, 0.15);
	overflow: hidden;
	&TextContainer {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		padding: 1rem;
	}

	&Title {
		text-align: left;
		font-size: 2rem;
		font-family: 'Lusitana', serif;
		border-bottom: 2px solid colors.$color-primary-light;
		display: inline;
		padding-bottom: 0.6rem;
		font-weight: 700;
		text-transform: uppercase;
	}

	&Lead {
		font-size: 1.4rem;
	}
	&:hover {
		background-color: colors.$color-primary;
	}
	&Image {
		&Container {
			position: relative;
		}
		&Hover {
			position: absolute;
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			height: 100%;
			justify-content: center;
			background-color: colors.$color-white;
			opacity: 85%;

			z-index: 5;
		}
	}
}

// ABOVE 544px (For smaller tablets)
@media only screen and (min-width: 34em) {
}

// ABOVE 704px (For Tablets)
@media only screen and (min-width: 44em) {
}
// ABOVE 944px (For Landscape tablets)
@media only screen and (min-width: 59em) {
	.articleCard {
		&Title {
			min-height: 7.8rem;
		}

		&Lead {
			min-height: 5.8rem;
		}
	}
}

// ABOVE 1200px (For smaller desktops)
@media only screen and (min-width: 75em) {
	.articleCard {
		min-width: 44rem;
	}
}

// ABOVE 1344px (For desktops)
@media only screen and (min-width: 84em) {
}
