@use '../abstracts/colors';
@use '../base/typography';
@use '../base/animations';
@use '../base/utilities';

.mission {
	@apply flex justify-center items-center flex-col my-28 gap-24 mx-8;
	&Title {
		color: colors.$color-grey-dark;
		font-size: 2rem;
		font-weight: 400;
		letter-spacing: 0.2rem;
		font-weight: 400;
		font-family: 'Lusitana', serif;
		@apply flex justify-center items-center text-center;
	}
	&Description {
		font-size: 1.2rem;
		font-weight: 400;
		letter-spacing: 0.1rem;
		font-weight: 400;
		@apply flex justify-center items-center text-center;
		&Container {
			@apply flex justify-center items-center text-center;
		}
	}
}

// ABOVE 544px (For smaller tablets)
@media only screen and (min-width: 34em) {
}

// ABOVE 704px (For Tablets)
@media only screen and (min-width: 44em) {
}
// ABOVE 944px (For Landscape tablets)
@media only screen and (min-width: 59em) {
}

// ABOVE 1200px (For smaller desktops)
@media only screen and (min-width: 75em) {
	.mission {
		@apply flex justify-center items-center flex-col my-28;
		&Title {
			font-size: 2.8rem;
			font-weight: 400;
			letter-spacing: 0.2rem;
			font-weight: 400;
			@apply flex justify-center items-center text-center;
		}
		&Description {
			font-size: 1.6rem;
			font-weight: 400;
			letter-spacing: 0.2rem;
			font-weight: 400;
			@apply flex justify-center items-center text-center;
			&Container {
				@apply flex justify-center items-center text-center w-1/3;
			}
		}
	}
}

// ABOVE 1344px (For desktops)
@media only screen and (min-width: 84em) {
}
