@use '../abstracts/colors';
@use '../base/animations';

.headingPrimary {
	letter-spacing: 0.2rem;
	backface-visibility: hidden;
	font-size: 2.4rem;
	font-weight: 300;
	font-family: 'RALEWAY', serif;
	animation: scaleIn 1s forwards;
	line-height: normal;
	&Sub {
		display: block;
		font-size: 2rem;
		font-weight: 300;
		letter-spacing: 0.2rem;
		animation: scaleIn 1s forwards;
	}
}

.headingSecondary {
	display: inline-block;
	font-size: 2rem;
	text-transform: uppercase;
	font-weight: 400;
	font-family: 'Lusitana', serif;
	letter-spacing: 0.2rem;
	animation: moveInBottom 1s forwards;
}

.headingTertiary {
	font-size: 1.6rem;
	font-weight: 700;
	text-transform: uppercase;
}

.paragraph {
	font-size: 1.6rem;
	&:not(:last-child) {
		margin-bottom: 3rem;
	}
}

// ABOVE 544px (For smaller tablets)
@media only screen and (min-width: 34em) {
}

// ABOVE 704px (For Tablets)
@media only screen and (min-width: 44em) {
}
// ABOVE 944px (For Landscape tablets)
@media only screen and (min-width: 59em) {
}

// ABOVE 1200px (For smaller desktops)
@media only screen and (min-width: 75em) {
	.headingPrimary {
		font-size: 5rem;
		letter-spacing: 0.2rem;
	}
	.headingSecondary {
		display: inline-block;
		font-size: 2.8rem;
		text-transform: uppercase;
		font-weight: 400;
		letter-spacing: 0.2rem;
		animation: moveInBottom 1s forwards;
	}
}

// ABOVE 1344px (For desktops)
@media only screen and (min-width: 84em) {
}
